import styled, { css } from "styled-components";
import iconEmail from "../assets/email.svg"
import iconSenha from "../assets/senha.svg"
import iconNome from "../assets/nome.svg"
import iconEye from "../assets/eye.svg"
import iconEyeSlash from "../assets/eye-slash.svg"

export const Container = styled.div`
  margin: 0 auto;
  width: auto;
  height: 100%;

  ${(props) =>
    props.fluid &&
    css`
      padding: 0;
      margin: 0;
      max-width: 100%;
    `}
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  ${(props) =>
    props.center &&
    css`
      justify-content: center;
    `};
  ${(props) =>
    props.column &&
    css`
      flex-direction: column;
    `}
`;

export const Box = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 530px;
    width: calc(100% - 48px);
    min-height: 100vh;
    margin: 0 auto;
    ${(props) =>
        props.center &&
    css`
        justify-content: center;
    `};
    ${(props) =>
        props.column &&
    css`
        flex-direction: column;
    `}
    @media (max-width: 690px) {
        width: 98%;
    }
`;

export const InnerBox = styled.div`
    display: flex;
    flex-direction: column;
    padding: 48px 40px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background: ${(props) => props.theme.secondaryBackground};
    border-radius: 14px;
    ${(props) =>
        props.center &&
    css`
        justify-content: center;
    `};
    ${(props) =>
        props.column &&
    css`
        flex-direction: column;
    `}
    @media (max-width: 690px) {
        margin: 0;
        min-height: 99vh;
    }
`;

export const Label = styled.label`
    font: 300 14px/1 "Ubuntu", sans-serif;
    margin: 0 0 14px 0;
    display: block;
    width: 100%;
    -webkit-transition: 300ms ease;
    transition: 300ms ease;
    color: ${(props) => props.theme.text};
    ${(props) =>
        props.center &&
    css`
        justify-content: center;
    `};
    ${(props) =>
        props.column &&
    css`
        flex-direction: column;
    `}
`;

export const Input = styled.input`
    background: ${(props) => props.theme.secondaryBackground};
    border: ${(props) => props.theme.inputBorder};
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    border-radius: 6px;
    padding: 17px 72px 19px 19px;
    -webkit-transition: 300ms ease;
    transition: 300ms ease;
    margin: 0 0 20px 0;
    color: ${(props) => props.theme.text};
    &:focus {
        background-color: ${(props) => props.theme.inputFocusBackground};
    }
`;

export const Title = styled.h2`
  font-size: 1.5em;
  margin: 44px 0 30px 0;
  color: ${(props) => props.theme.text};
`;

export const Button = styled.button`
    padding-top: 15px;
    padding-bottom: 18px;
    width: 100%;
    color: #fff;
    border: none;
    border-radius: 6px;
    font: 500 17px/1 "Ubuntu", sans-serif;
    -webkit-transition: 400ms;
    transition: 400ms;
    display: inline-block;
    margin: 8px 0 0 0;
    text-align: center;
    cursor: pointer;
    background: ${(props) => props.theme.backgroundButton};
    background-size: 100% 100%;
    &:hover {
        background-size: 250% 200%;
    }
    &:disabled {
        background: ${(props) => props.theme.backgroundButtonDisabled};
    }
`;

export const LogoLobbyze = styled.img`
    display: ${(props) => props.theme.displayLobbyzeLogo};
    margin: 0 auto;
    width: 100%;
`;

export const LogoSlotPoker = styled.img`
    display: ${(props) => props.theme.displaySlotLogo};
    margin: 0 auto;
    width: 100%;
`;

export const FieldsetName = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    position: relative;
    margin: 0 0 10px 0;
    &:before {
        width: 22px;
        height: 22px;
        display: inline-block;
        content: '';
        position: absolute;
        bottom: 37px;
        right: 20px;
        -webkit-mask: url(${iconNome}) no-repeat 50% 50%;
        mask: url(${iconNome}) no-repeat 50% 50%;
        -webkit-mask-size: contain;
        mask-size: contain;
        background-color: ${(props) => props.theme.inputIconBackground};
    }
`;

export const FieldsetEmail = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    position: relative;
    margin: 0 0 10px 0;
    &:before {
        width: 22px;
        height: 22px;
        display: inline-block;
        content: '';
        position: absolute;
        bottom: 37px;
        right: 20px;
        -webkit-mask: url(${iconEmail}) no-repeat 50% 50%;
        mask: url(${iconEmail}) no-repeat 50% 50%;
        -webkit-mask-size: contain;
        mask-size: contain;
        background-color: ${(props) => props.theme.inputIconBackground};
    }
`;

export const FieldsetPass = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    position: relative;
    margin: 0 0 10px 0;
`;

export const LockPassIcon = styled.span`
width: 22px;
height: 22px;
display: inline-block;
content: '';
position: absolute;
bottom: 37px;
right: 20px;
-webkit-mask: url(${iconSenha}) no-repeat 50% 50%;
mask: url(${iconSenha}) no-repeat 50% 50%;
-webkit-mask-size: contain;
mask-size: contain;
background-color: ${(props) => props.theme.inputIconBackground};
`

export const EyePassIcon = styled.span`
    display: inline-block;
    content: '';
    position: absolute;
    width: 30px;
    height: 30px;
    bottom: 33px;
    right: 16px;
    &:before {
        pointer-events: none;
        display: inline-block;
        content: '';
        position: absolute;
        left: 4px;
        top: 4px;
        width: 22px;
        height: 22px;
        background-color: ${(props) => props.theme.inputIconBackground};
        -webkit-mask: url(${({pressed}) => pressed ? iconEye : iconEyeSlash}) no-repeat 50% 50%;
        mask: url(${({pressed}) => pressed ? iconEye : iconEyeSlash}) no-repeat 50% 50%;
        -webkit-mask-size: contain;
        mask-size: contain;
    }
    &:hover {
        opacity: 0.7;
    }
    ${(props) => props.pressed ? `background-color: ${props.theme.inputIconBackgroundTranslucent};` : ''}
`

export const ErrorSpan = styled.div`
    position: absolute;
    right: 0;
    font-size: 12px;
    color: #FF0000
`

export const VerticalExpander = styled.div`
    width: 100%;
    flex-grow: 1;
`

export const Span = styled.span`
    font: 300 12px/1 "Ubuntu", sans-serif;
    margin-left: 6px;
    opacity: 0.9;
    color: ${(props) => props.theme.text};
    ${(props) =>
        props.center &&
    css`
        justify-content: center;
    `};
    ${(props) =>
        props.column &&
    css`
        flex-direction: column;
    `}
`
