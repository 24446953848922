import { useState, useEffect } from "react";
import  secureLocalStorage  from  "react-secure-storage";
import { useTranslation } from "react-i18next";

export const StdValidations = Object.freeze({
  validEmail: (next) => function({value, display, ctx}) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if(!re.test(value)) {
      return ctx.t('err.email')
    }
    return next && next.apply(this, arguments)
  },
  length: (min, max, next) => function({value, display, ctx}) {
    if(!value || value.length < min) {
        return ctx.t('err.atLeast', {
          field: display,
          min
        })
    }
    if(max && value.length > max) {
      return ctx.t('err.atMost', {
        field: display,
        max
      })
    }
    return next && next.apply(this, arguments)
  },
  equalsField: (another, next) => function({value, display, ctx}) {
    if(value !== ctx.values[another]) {
      return ctx.t('err.notequal', {
        field: display,
      })
    }
    return next && next.apply(this, arguments)
  }
})

export function useForm(rawSubmit, defaultValues = {}) {

  const { t } = useTranslation();

  const [values, setValues] = useState(defaultValues);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    let user = secureLocalStorage.getItem("storedUser");
    if(user) {
      setValues({ login: user.email, password: user.pass, remember: true });
    } else {
      setValues({});
    }
  }, []);

  const validations = [];
  const display = {};

  function submit() {

    for(const v of validations) {
      if(!v()) {
        return
      }
    }

    rawSubmit()
  }

  const ctx = {
    t,
    values,
    errors,
    display,
    submit,
  }

  function createField(name, thisDisplay, rawValidate, trim = true, defaultValue = null) {
    if(values[name] === undefined && defaultValue) {
      values[name] = defaultValue;
    }
    function deleteErr() {
      setErrors((prev) => {
        const copy = { ...prev };
        delete copy[name];
        return copy
      });
    }
    function validate() {
      let value = values[name]
      if (typeof value === 'string' && trim) {
        let prevValue = value;
        value = value.trim()
        if (prevValue !== value) {
          setValues((prev) => ({ ...prev, [name]: value }))
        }
      }

      const err = rawValidate({ value, name, display: thisDisplay, ctx: ctx});
      if (err) {
        setErrors((prev) => ({ ...prev, [name]: err }));
        return false;
      } else {
        deleteErr();
        return true;
      }
    }
    validations.push(validate);
    display[name] = thisDisplay;
    const onChange = (e) => {
      const value = e.target.type === 'checkbox' ? e.target.checked : (e.target.value || '');
      if(values[name] === value) {
        return
      }
      setValues((prev) => {
        return ({
          ...prev,
          [name]: value,
        })
      });
    }
    return {
      value: values[name] || '',
      checked: values[name] || false,
      onChange: (e) => onChange(e),
      onBlur: () => validate(),
      onKeyDown: (event) => {
        if (event.key === "Enter") {
          submit()
        } else {
          deleteErr()
        }
      },
    };
  }

  return {
    ...ctx,
    createField,
  };
}
